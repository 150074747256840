<template>
  <el-container>
    <el-header class="flex fjsb fac">
      <section class="logo flex fjsb fac">
        <img src="@/assets/img/home/logo.png" alt="logo">
        <div class="flex fd ml-24">
          <span class="fs-30">运动数据管理平台</span>
          <span class="fs-19">Motion data management</span>
        </div>
      </section>
      <section class="header-right flex fjsb fac">
        <div class="flex fjc fac mr-20 cursor-default" @click="gotoHomePage">
          <img src="@/assets/img/detail/data.png" alt="实时数据" class="real-time mr-6">
          <span>实时数据</span>
        </div>
        <div class="flex fjc fac mr-20 cursor-default" @click="exitLogin">
          <img src="@/assets/img/home/close.png" alt="退出登陆" class="logout mr-6">
          <span>退出登陆</span>
        </div>
        <el-avatar :src="userInfo.fileDynamic.Avatar" class="mr-10">
        </el-avatar>
        <span class="fs-20">{{userInfo.nickname}}</span>
      </section>
    </el-header>
    <el-main class="flex fjsb fac fw">
      <el-form class="flex fjsb fac wd-100" label-width="160px" ref="form" :model="form">
        <el-form-item prop="userId">
          <div slot="label" class="flex fjsa fac">
            <img src="../assets/img/detail/Athletes.png" alt="" class="player">
            运动员:
          </div>
          <el-select v-model="form.userId" placeholder="请选择" class="player-select">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              {{item.label}}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="trainingTime">
          <div slot="label" class="flex fjsa fac">
            <img src="../assets/img/detail/date.png" alt="" class="time">
            训练时间:
          </div>
          <el-date-picker v-model="form.trainingTime" type="daterange" align="right" unlink-panels range-separator="至"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <div class="mb-22 flex">
          <el-button round type="primary" @click="submitForm('form')">查询</el-button>
          <el-button round type="primary" @click="downForm('form')">下载</el-button>
        </div>
      </el-form>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="计划组" name="first">
          <div class="table-page">
            <el-table :header-cell-style="{background: 'transparent',color: '#FFFFFF',lineHeight: '40px',
            fontSize: '16px',borderBottomColor: '#FFA200'}" ref="multipleTable" :data="tableData" tooltip-effect="dark"
              style="width: 100%">
              <el-table-column align="center" label="运动员">
                <template slot-scope="scope">{{ scope.row.dictDynamic.UserId }}</template>
              </el-table-column>
              <el-table-column align="center" label="计划组">
                <template slot-scope="scope">{{ scope.row.pgName }}</template>
              </el-table-column>
              <el-table-column align="center" label="训练进度">
                <template slot-scope="scope">{{parseTrainingCount(scope.row)}}</template>
              </el-table-column>
              <el-table-column prop="name" align="center" label="训练时间">
                <template slot-scope="scope">{{ scope.row.trainingBeginTime }}</template>
              </el-table-column>
              <el-table-column prop="name" align="center" label="创建时间">
                <template slot-scope="scope">{{ scope.row.createTime }}</template>
              </el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <el-button type="primary" @click="queryPlanList(scope.row)">查看计划</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination class="flex fjc page" background @current-change="handleCurrentChangePlanGroup"
              :current-page.sync="formPage.pageIndex" :page-size="formPage.pageSize" layout="prev, pager, next"
              :total="formPage.total">
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="'计划组训练详情' + activeNameShow" name="second">
          <div class="table-page">
            <el-table :header-cell-style="{background: 'transparent',
          color: '#FFFFFF',lineHeight: '40px',
          fontSize: '16px',borderBottomColor: '#FFA200'}" ref="multipleTable" :data="tableData2" tooltip-effect="dark"
              style="width: 100%">
              <el-table-column align="center" label="训练模式">
                <template slot-scope="scope">{{ scope.row.planName }}</template>
              </el-table-column>
              <el-table-column align="center" label="次数">
                <template slot-scope="scope">{{ parseTrainingPlanCount(scope.row) }}</template>
              </el-table-column>
              <el-table-column align="center" label="向心力">
                <template slot-scope="scope">{{ scope.row.powerIn }}</template>
              </el-table-column>
              <el-table-column align="center" label="离心力">
                <template slot-scope="scope">{{ scope.row.powerOut }}</template>
              </el-table-column>
              <el-table-column prop="name" align="center" label="向心速度">
                <template slot-scope="scope">{{ scope.row.speedIn }}</template>
              </el-table-column>
              <el-table-column prop="name" align="center" label="离心速度">
                <template slot-scope="scope">{{ scope.row.speedOut }}</template>
              </el-table-column>
              <el-table-column prop="name" align="center" label="弹性系数">
                <template slot-scope="scope">{{ scope.row.elasticRatio }}</template>
              </el-table-column>
              <el-table-column prop="name" align="center" label="阻力系数">
                <template slot-scope="scope">{{ scope.row.resistanceRatio }}</template>
              </el-table-column>
              <el-table-column prop="name" align="center" label="训练状态">
                <template slot-scope="scope">{{ parseTrainingStatus(scope.row) }}</template>
              </el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <el-button type="primary" @click="openHistogram(scope.row)">
                    图表数据
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination class="flex fjc page" background @current-change="handleCurrentChangePlan"
              :current-page.sync="planFormPage.pageIndex" :page-size="planFormPage.pageSize" layout="prev, pager, next"
              :total="planFormPage.total">
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
      <bar-chart-detail :planId="planId" :drawer="drawer" :drawerName="drawerName" @handleCloseDrawer="drawer = false">
      </bar-chart-detail>
    </el-main>
  </el-container>
</template>

<script>
  import BarChartDetail from '../components/bar-chart-detail';
  import {
    Message
  } from 'element-ui'
  export default {
    name: 'detail',
    components: {
      BarChartDetail
    },
    data() {
      return {
        userId: this.$storage.getUserId(),
        userInfo: this.$storage.getUserInfo(),
        currentPage: 5,
        planId: null,
        drawer: false,
        drawerName: '',
        tableData: [],
        tableData2: [],
        radio: 1,
        activeName: 'first',
        activeNameShow: '',
        options: [],
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        nickname: null,
        form: {
          limit: null,
          offset: null,
          trainingTime: [],
          userId: null
        },
        formPage: {
          total: 0,
          pageIndex: 1,
          pageSize: 6
        },
        planForm: {
          limit: null,
          offset: null,
          userId: null,
          planGroupId: null
        },
        planFormPage: {
          total: 0,
          pageIndex: 1,
          pageSize: 6
        },
      }
    },
    created: function() {
      console.log("created-home", this.userInfo)
      this.queryBindUserList();
      this.queryPlanGroupList();
    },
    methods: {
      parseTrainingStatus: function(row) {
        if (row.currentCount == null || row.currentCount === 0) {
          return "待训练";
        }

        if (row.exerciseFlag != null && row.exerciseFlag === true) {
          if ((new Date().getTime() - new Date(row.trainingBeginTime).getTime()) > 3600 * 1000 * 24) {
            return "训练完成";
          }
          return "训练中";
        }

        return "训练完成";
      },
      parseNull: function(val) {
        if (val == null) {
          return "--"
        }
        return val;
      },
      handleCurrentChangePlanGroup(val) {
        this.queryPlanGroupList();
      },
      handleCurrentChangePlan(val) {
        console.log(`当前页: ${val}`);
        this.queryPlanList(null);
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log('submit!!', this.form);
            this.queryPlanGroupList();
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      downForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log('submit!!', this.form);
            let form = JSON.parse(JSON.stringify(this.form))
            if (form.userId == null) {
              form.userId = this.userId;
            } else if (form.userId == this.userInfo.nickname) {
              form.userId = this.userId;
            }

            if (form.trainingTime != null && form.trainingTime.length === 2) {
              form.trainingBeginTime = new Date(form.trainingTime[0]).getTime();
              form.trainingEndTime = new Date(form.trainingTime[1]).getTime() + 3600 * 1000 * 24;
            }

            delete form.trainingTime;
            delete form.limit;
            delete form.offset;
            this.$api.downData(form).then(function (response) {
              console.log(response);
              if(response.data.type === "application/octet-stream") {
                const filename = response.headers["content-disposition"];
                const blob = new Blob([response.data], {type: "application/zip"});
                let downloadElement = document.createElement("a");
                let href = window.URL.createObjectURL(blob);
                downloadElement.href = href;
                downloadElement.download = decodeURI(filename.split("filename=")[1]);
                document.body.appendChild(downloadElement);
                downloadElement.click();
                document.body.removeChild(downloadElement);
                window.URL.revokeObjectURL(href);
              }
            })
              .catch(function (error) {
                console.log(error);
              });
          } else {
            console.log('error submit!!');
          }
        });
      },
      queryBindUserList: function() {
        this.options = [];
        this.options.push({
          label: this.userInfo.nickname,
          value: this.userInfo.userId
        })
        this.$api.queryBindUserList({
          limit: 500,
          offset: 0,
          bindStatus: 'bind',
          userId: this.userId
        }).then(res => {
          if (res.success) {
            if (res.data != null && res.data.length > 0) {
              for (var i = 0; i < res.data.length; i++) {
                this.options.push({
                  label: res.data[i].nickname,
                  value: res.data[i].toUserId
                })
              }
            }
            this.form.userId = this.userInfo.nickname;
          }
        })
      },
      queryPlanGroupList: function() {
        let form = JSON.parse(JSON.stringify(this.form));
        if (form.userId == null) {
          form.userId = this.userId;
        } else if (form.userId == this.userInfo.nickname) {
          form.userId = this.userId;
        }

        form.offset = (this.formPage.pageIndex - 1) * this.formPage.pageSize;
        form.limit = this.formPage.pageSize;

        if (form.trainingTime != null && form.trainingTime.length === 2) {
          form.trainingBeginTime = new Date(form.trainingTime[0]).getTime();
          form.trainingEndTime = new Date(form.trainingTime[1]).getTime() + 3600 * 1000 * 24;
        }
        delete form.trainingTime;
        this.tableData = [];
        this.tableData2 = [];
        this.activeName = 'first';
        this.activeNameShow = '';
        this.$api.queryPlanGroupList(form).then(res => {
          if (res.success) {
            this.tableData = res.data;
            this.formPage.total = res.total;
            this.formPage.pageIndex = res.pageIndex;
            this.formPage.pageSize = res.pageSize;
          }
        })
      },
      queryPlanList: function(row) {
        if (row != null) {
          this.planForm.userId = row.userId;
          this.planForm.planGroupId = row.planGroupId;
        }

        this.planForm.offset = (this.planFormPage.pageIndex - 1) * this.planFormPage.pageSize;
        this.planForm.limit = this.planFormPage.pageSize;

        this.$api.queryPlanList(this.planForm).then(res => {
          if (res.success) {
            this.tableData2 = res.data;
            this.planFormPage.total = res.total;
            this.planFormPage.pageIndex = res.pageIndex;
            this.planFormPage.pageSize = res.pageSize;
            this.activeName = 'second';
            this.activeNameShow = "【" + row.pgName + "】";
          }
        })
      },
      openHistogram: function(row) {
        if (row.recordFlag === true) {
          this.planId = row.planId
          this.drawer = true;
          this.drawerName = row.planName;
        } else {
          Message({
            message: '当前计划还没开始训练，请训练后再查看图表数据',
            type: 'warning',
            duration: 3000
          })
        }

      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      handleClick(tab, event) {
        console.log(tab, event);
      },
      exitLogin: function() {
        this.$confirm('您确认退出登陆吗？')
          .then(e => {
            this.$storage.exitLogin();
            this.$router.push({
              name: 'login'
            })
          })
          .catch(e => {

          });
      },
      gotoHomePage: function() {
        this.$router.push({
          name: 'home'
        })
      },
      parseTrainingCount: function(row) {
        //{{ row.planSchedule + "/" +.row.planAmount }}
        let planSchedule = 0;
        let planAmount = 0;
        if (row.planSchedule != null) {
          planSchedule = row.planSchedule;
        }
        if (row.planAmount != null) {
          planAmount = row.planAmount;
        }
        return planSchedule + "/" + planAmount;
      },
      parseTrainingPlanCount: function(row) {
        let currentCount = 0;
        let totalCount = 0;
        if (row.currentCount != null) {
          currentCount = row.currentCount;
        }
        if (row.totalCount != null) {
          totalCount = row.totalCount;
        }
        return currentCount + "/" + totalCount;
      }
    }
  }
</script>

<style scoped lang="less">
  .cursor-default {
    cursor: pointer
  }
  /deep/.el-table--enable-row-hover .el-table__body tr:hover>td{
    background-color: #505050;
  }
  .el-container {
    width: 100vw;
    //height: 100vh;
    background: url("../assets/img/home/background.jpg") 100% 100% no-repeat;
    background-size: 100% 100%;


    .el-header {
      width: 100vw;
      height: 0.9rem !important;
      background: #FFA200;
      font-size: 0.16rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;

      .logo {
        img {
          width: 0.57rem;
          height: 0.52rem;
        }

        div {
          span:nth-child(1) {
            font-weight: bold;
          }

          span:nth-child(2) {
            font-weight: 300;
            letter-spacing: 0.02rem;
          }
        }
      }

      .header-right {
        .real-time {
          width: 31px;
          height: 26px;
        }

        .logout {
          height: 0.28rem;
          width: 0.28rem;
        }

        .is-checked /deep/ .el-radio__inner {
          border-color: #000000;
          background: #000000;
        }

        .is-checked /deep/ .el-radio__label {
          color: #000000;
        }

        .el-avatar {
          width: 0.6rem;
          height: 0.6rem;
        }
      }
    }
  }

  .el-main {
    margin: 37px 92px;
    padding: 0;

    .time {
      width: 37px;
      height: 36px;
    }

    .player {
      width: 34px;
      height: 39px;
    }

    .player-select {
      width: 326px;

      /deep/.el-input__inner {
        width: 100%;
        font-size: 20px;
        color: #FFFFFF;
        font-family: Microsoft YaHei;
        font-weight: 300;
        line-height: 27px;
      }
    }

    /deep/.el-form-item__label {
      font-size: 23px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      color: #FFFFFF;
      line-height: 47px;
    }

    /deep/.el-input__inner {
      width: 412px;
      background: transparent;

      .el-range-input {
        background: transparent;
        font-size: 23px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 34px;
      }

      .el-range-separator {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: #999999;
        line-height: 27px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .el-button--default {
      background: transparent;
      border-color: #FFA200;
      color: #FFA200;
    }

    button {
      font-size: 23px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      color: #FFFFFF;
      width: 139px;
      height: 45px;
      border-radius: 22px;
    }

    .el-tabs {
      position: relative;
      width: 100%;
    }

    /deep/.el-tabs__header {
      margin-bottom: 30px;
      line-height: 61px;
      font-size: 27px;
      height: 40px;

      .el-tabs__nav-wrap::after {
        background: transparent;
      }

      .el-tabs__item {
        font-size: 27px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 61px;
      }
    }

    /deep/.el-tabs__content {
      background: url("../assets/img/detail/right-bg.png") no-repeat 100% 100% #333333;
      background-size: 100% 100%;
      width: 100%;
      height: 660px;
      opacity: 0.8;
      border-radius: 5px 0 0 5px;
    }

    .el-table {
      &::before {
        background: transparent;
      }

      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      background: transparent;

      /deep/tr {
        background: transparent;
        height: 80px;
      }

      /deep/.cell {
        line-height: 27px;
      }

      button {
        width: 110px;
        height: 37px;
        border-radius: 5px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
      }
    }

    .el-tab-pane {
      height: 100%;
    }

    .table-page {
      height: 96%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
    }

    .page {
      align-items: flex-end;
    }
  }
</style>
