<template>
  <el-drawer
    :title="drawerName"
    :visible.sync="drawer"
    direction="ltr"
    size="90%"
    custom-class="demo-drawer1"
    :before-close="handleClose">
    <section class="right-panel">
      <div class="mid-height">
        <div class="echarts">
          <div class="title" v-show="chooseBtn === 0">峰值功率</div>
          <div class="title" v-show="chooseBtn === 1">峰值速度</div>
          <div class="title" v-show="chooseBtn === 2">RFD</div>
          <div id="detail-bar-1" class="home-bar-echarts"></div>
        </div>
        <div class="echarts" v-show="chooseBtn === 0 || chooseBtn === 1">
          <div class="title" v-show="chooseBtn === 0">平均功率</div>
          <div class="title" v-show="chooseBtn === 1">平均速度</div>
          <div id="detail-bar-2" class="home-bar-echarts"></div>
        </div>
      </div>
      <div class="right-btn">
        <div :class="chooseBtn === 0?'active':''" @click="changeBtn(0)">功率</div>
        <div :class="chooseBtn === 1?'active':''" @click="changeBtn(1)">速度</div>
        <div :class="chooseBtn === 2?'active':''" @click="changeBtn(2)">RFD</div>
      </div>
    </section>
  </el-drawer>

</template>

<script>
  export default {
    name: 'bar-chart-detail',
    data() {
      return {
        chooseBtn: 1,
        myChart1: null,
        myChart2: null,
        chartsData: null,
        options: {
          dataZoom: [{
              show: false, //是否显示下方滚动条
              realtime: true,
              startValue: 0, //显示数据开始位置
              endValue: 15 //显示数据结束位置
            },
            {
              type: 'inside',
              zoomLock:true,
              realtime: true
            }
          ],
          label: {
            show: true,
            position: 'top',
            color: '#FFF',
            fontSize: 14
          },
          grid: {
            show:false,
            left: '0',
            right: '0',
            bottom: '2%',
            containLabel: true
          },
          xAxis: [{
            offset: 5,
            type: 'category',
            data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10,11,12,13,14,15],
            position: 'bottom',
            axisLabel: {
              show: true,
              textStyle: {
                color: '#fff'
              }
            }
          }],
          yAxis: [{
            type: 'value',
            axisLabel: {
              show: true,
              textStyle: {
                color: '#fff'
              }
            },
            splitLine:{
              show:false
            }
          }],
          series: [{
            data: [],
            type: 'bar',
            barWidth:20,
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0,
                color: '#feb812' // 0% 处的颜色
              }, {
                offset: 1,
                color: '#ff7e42' // 100% 处的颜色
              }],
              globalCoord: false // 缺省为 false#909090 #686868
            },
            itemStyle: {
              borderRadius: [50, 50, 0, 0]
            }

          }, {
            data: [],
            type: 'bar',
            barWidth:20,
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0,
                color: '#909090' // 0% 处的颜色
              }, {
                offset: 1,
                color: '#686868' // 100% 处的颜色
              }],
              globalCoord: false // 缺省为 false
            },
            itemStyle: {
              borderRadius: [50, 50, 0, 0]
            }
          }]
        }
      }
    },
    props: {
      planId: {
        type: Number,
        default: null
      },
      drawer:{
        type: Boolean,
        default: false
      },
      drawerName:{
        type:String,
        default:null
      }
    },
    watch: {
      planId(newVal, oldVal) {
        console.log('planId', oldVal, newVal)
        this.queryPlanRecord(newVal);
      }
    },
    mounted() {

    },
    methods: {
      handleClose:function(){
        this.$emit('handleCloseDrawer')
      },
      // 切换按钮
      changeBtn(e) {
        this.chooseBtn = e;
        this.buildChartsData()
      },
      queryPlanRecord: function(planId) {
        let param = {
          limit: 1000,
          offset: 0,
          planId: planId
        }
        this.$api.queryPlanRecord(param).then(res => {
          if (res.success) {
            //console.log("queryPlanRecord", res);
            this.chartsData = res.data;
            this.buildChartsData()
          }
        })
      },
      buildChartsData: function() {
        if(this.myChart1 == null){
          this.myChart1 = this.$echarts.init(document.getElementById('detail-bar-1'))
        }
        if(this.myChart2 == null){
          this.myChart2 = this.$echarts.init(document.getElementById('detail-bar-2'))
        }

        var charts1 = JSON.parse(JSON.stringify(this.options));
        var charts2 = JSON.parse(JSON.stringify(this.options));
        charts1.xAxis[0].data = this.chartsData.countList
        charts2.xAxis[0].data = this.chartsData.countList
        if (this.chooseBtn === 0) {
          //功率
          charts1.series[0].data = this.chartsData.powerPeakInList;
          charts1.series[1].data = this.chartsData.powerPeakOutList;
          charts2.series[0].data = this.chartsData.powerAvgInList;
          charts2.series[1].data = this.chartsData.powerAvgOutList;
        } else if (this.chooseBtn === 1) {
          //速度
          charts1.series[0].data = this.chartsData.speedPeakInList;
          charts1.series[1].data = this.chartsData.speedPeakOutList;
          charts2.series[0].data = this.chartsData.speedAvgInList;
          charts2.series[1].data = this.chartsData.speedAvgOutList;
        } else if (this.chooseBtn === 2) {
          //RFD
          charts1.series[0].data = this.chartsData.rfdInList;
          charts1.series[1].data = this.chartsData.rfdOutList;
        }
        this.myChart1.setOption(charts1, true);
        this.myChart2.setOption(charts2, true);
      }
    }
  }
</script>

<style scoped lang="less">

  /deep/.demo-drawer1 {
    background: url("../assets/img/home/background3.png") 100% 100% no-repeat;
    background-size: 100% 100%;
  }

  .right-panel {
    width: 100%;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .echarts {
      width: 90%;
      height: 280px;
      margin-bottom: 10px;

      &:nth-child(1) {
        margin-top: 50px;
      }

      .title {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: #FFFFFF;
        line-height: 37px;
      }

      .home-bar-echarts {
        width: 100%;
        height: 250px;
        z-index: 99999;
      }
    }

    .right-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFA200;
      line-height: 27px;
      cursor: pointer;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 118px;
        height: 41px;
        border: 2px solid #FFA200;

        &:hover {
          background: #FFA200;
          color: #FFFFFF;
          transition: all 0.15s linear;
        }

        &.active {
          background: #FFA200;
          color: #FFFFFF;
        }
      }

      div:nth-child(1) {
        border-radius: 21px 0 0 21px;
      }

      div:nth-child(2) {
        border-radius: 0 0 0 0;
        margin: 0 2px;
      }

      div:nth-child(3) {
        border-radius: 0 21px 21px 0;
      }
    }
  }
  .mid-height{
    height: 700px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
</style>
